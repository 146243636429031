<template>
  <div class="leave-kanban">
    <div class="kanban-view-list">
      <v-flex class="kanban-innerlist">
        <div class="list-title">
          <div class="d-flex">
            <div class="fw-600 title-text">Pending</div>
            <v-spacer></v-spacer>
            <v-checkbox
              v-on:change="manageAllCheckboxPending"
              v-model="allPendingCheckbox"
              hide-details
              class="ma-0 pa-0 size-16"
            >
            </v-checkbox>
          </div>
          <div class="mt-1">
            <v-chip
              class="mb-1 mr-1"
              label
              color="orange darken-4 white--text"
              x-small
            >
              {{ pending.length }} leave
            </v-chip>
          </div>
        </div>
        <Container
          group-name="leadkanbanlist"
          @drop="onPendingDrop('pending', $event)"
          :get-child-payload="getChildPayload1"
          :drop-placeholder="dropPlaceholderOptions"
          class="overflow-list-item"
          :id="!pending.length ? 'blank-list' : ''"
        >
          <Draggable v-for="(data, index) in pending" :key="index" class="">
            <div class="list-items" v-if="data">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Staff :</span>
                <span class="fw-500">{{ data.staff.full_name }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date : </span>
                <span class="fw-500">{{ data.start_date }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ data.end_date }}</span>
              </div>
              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>
              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
              <v-checkbox
                hide-details
                v-bind:value="data.id"
                v-on:change="manageSelectBox"
                v-model="pendingCheckbox"
                class="ma-0 pa-0 size-16"
              >
              </v-checkbox>
              <v-icon
                class="ma-0 pa-0 size-16 float-right"
                v-on:click="updateLeave(data.id)"
                color="cyan"
                small
                >mdi-pencil</v-icon
              >
            </div>
          </Draggable>
          <v-btn
            v-if="pending.length > 0"
            class="mx-2 custom-bold-button"
            color="cyan white--text"
            v-on:click="getLeave('pending', current_page)"
          >
            Load More...
          </v-btn>
        </Container>
      </v-flex>
      <v-flex class="kanban-innerlist">
        <div class="list-title">
          <div class="d-flex">
            <div class="mr-2 fw-700 title-text">Approved</div>
            <v-spacer></v-spacer>
            <v-checkbox
              v-on:change="manageAllCheckboxApproved"
              v-model="allApproveCheckbox"
              hide-details
              class="ma-0 pa-0 size-16"
            >
            </v-checkbox>
          </div>
          <div class="mt-1">
            <v-chip class="mb-1 mr-1" label color="green white--text" x-small>
              {{ approved.length }} leave
            </v-chip>
          </div>
        </div>
        <Container
          group-name="leadkanbanlist"
          @drop="onApprovedDrop('approved', $event)"
          :get-child-payload="getChildPayload2"
          :drop-placeholder="dropPlaceholderOptions"
          class="overflow-list-item"
          :id="!approved.length ? 'blank-list' : ''"
        >
          <Draggable v-for="(data, index) in approved" :key="index" class="">
            <div class="list-items" v-if="data">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Staff : </span>
                <span class="fw-500">{{ data.staff.full_name }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date : </span>
                <span class="fw-500">{{ data.start_date }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ data.end_date }}</span>
              </div>
              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>
              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
              <v-checkbox
                hide-details
                v-bind:value="data.id"
                v-on:change="manageApproveSelectBox"
                v-model="aprroveCheckbox"
                class="ma-0 pa-0 size-16"
              >
              </v-checkbox>
              <v-icon
                class="ma-0 pa-0 size-16 float-right"
                v-on:click="updateLeave(data.id)"
                color="cyan"
                small
                >mdi-pencil</v-icon
              >
            </div>
          </Draggable>
          <v-btn
            v-if="approved.length > 0"
            class="mx-2 custom-bold-button"
            color="cyan white--text"
            v-on:click="getLeave('approved', current_pageone)"
          >
            Load More...
          </v-btn>
        </Container>
      </v-flex>
      <v-flex class="kanban-innerlist">
        <div class="list-title">
          <div class="d-flex">
            <div class="mr-2 fw-700 title-text">Rejected</div>
            <v-spacer></v-spacer>
            <v-checkbox
              v-on:change="manageAllCheckboxRejected"
              v-model="allRejectCheckbox"
              hide-details
              class="ma-0 pa-0 size-16"
            >
            </v-checkbox>
          </div>
          <div class="mt-1">
            <v-chip
              class="mb-1 mr-1"
              label
              color="red white--text darken-4"
              x-small
            >
              {{ rejected.length }} leave
            </v-chip>
          </div>
        </div>
        <Container
          group-name="leadkanbanlist"
          @drop="onRejectedDrop('rejected', $event)"
          :get-child-payload="getChildPayload3"
          :drop-placeholder="dropPlaceholderOptions"
          class="overflow-list-item"
          :id="!rejected.length ? 'blank-list' : ''"
        >
          <Draggable v-for="(data, index) in rejected" :key="index" class="">
            <div class="list-items" v-if="data">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Staff :</span>
                <span class="fw-500">{{ data.staff.full_name }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date :</span>
                <span class="fw-500">{{ data.start_date }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ data.end_date }}</span>
              </div>

              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>
              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
              <v-checkbox
                hide-details
                v-bind:value="data.id"
                v-on:change="manageRejectSelectBox"
                v-model="rejectCheckbox"
                class="ma-0 pa-0 size-16"
              >
              </v-checkbox>
              <v-icon
                class="ma-0 pa-0 size-16 float-right"
                v-on:click="updateLeave(data.id)"
                color="cyan"
                small
                >mdi-pencil</v-icon
              >
            </div>
          </Draggable>
          <v-btn
            v-if="rejected.length > 0"
            class="mx-2 custom-bold-button"
            color="cyan white--text"
            v-on:click="getLeave('rejected', current_pagetwo)"
          >
            Load More...
          </v-btn>
        </Container>
      </v-flex>
    </div>
  </div>
</template>
<script>
import { Container, Draggable } from "vue-smooth-dnd";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { QUERY, PATCH } from "@/core/services/store/request.module";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "LeaveKanban",
  data() {
    return {
      dropPlaceholderOptions: {
        className: "lead-drop-preview",
        animationDuration: "50",
        showOnTop: true,
      },
      categoryList: new Array(),
      category: null,
      allselect: false,
      leave: null,
      staff: null,
      start_date: null,
      userList: new Array(),
      end_date: null,
      allPendingCheckbox: false,
      allApproveCheckbox: false,
      allRejectCheckbox: false,
      reason: null,
      pageLoading: false,
      CreateLeave: false,
      update_loading: false,
      current_page: 1,
      current_pageone: 1,
      current_pagetwo: 1,
      pendingCheckbox: [],
      aprroveCheckbox: [],
      rejectCheckbox: [],
      pending: [],
      approved: [],
      rejected: [],
    };
  },
  watch: {},
  methods: {
    manageAllCheckboxPending(param) {
      if (param) {
        for (let i = 0; i < this.pending.length; i++) {
          this.pendingCheckbox.push(this.pending[i].id);
        }
      } else {
        this.pendingCheckbox = [];
      }
      this.manageSelectBox();
    },

    manageSelectBox() {
      if (this.pendingCheckbox.length > 0) {
        this.$emit("select-box", true);
        this.$emit("selected-rows", this.pendingCheckbox);
      } else {
        this.$emit("select-box", false);
        this.$emit("selected-rows", []);
      }
    },

    manageAllCheckboxApproved(param) {
      if (param) {
        for (let i = 0; i < this.approved.length; i++) {
          this.aprroveCheckbox.push(this.approved[i].id);
        }
      } else {
        this.aprroveCheckbox = [];
      }
      this.manageApproveSelectBox();
    },

    manageApproveSelectBox() {
      if (this.aprroveCheckbox.length > 0) {
        this.$emit("select-box", true);
        this.$emit("selected-rows", this.aprroveCheckbox);
      } else {
        this.$emit("select-box", false);
        this.$emit("selected-rows", []);
      }
    },
    manageAllCheckboxRejected(param) {
      if (param) {
        for (let i = 0; i < this.rejected.length; i++) {
          this.rejectCheckbox.push(this.rejected[i].id);
        }
      } else {
        this.rejectCheckbox = [];
      }
      this.manageRejectSelectBox();
    },

    manageRejectSelectBox() {
      if (this.rejectCheckbox.length > 0) {
        this.$emit("select-box", true);
        this.$emit("selected-rows", this.rejectCheckbox);
      } else {
        this.$emit("select-box", false);
        this.$emit("selected-rows", []);
      }
    },
    getLeave(status, curentPage) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "leave",
          data: { status: status, current_page: curentPage },
        })
        .then(({ data }) => {
          if (status == "pending") {
            _this.pending = _this.lodash.concat(_this.pending, data.rows);
            _this.current_page = data.current_page + 1;
          } else if (status == "approved") {
            _this.approved = _this.lodash.concat(_this.approved, data.rows);
            _this.current_pageone = data.current_page + 1;
          } else if (status == "rejected") {
            _this.rejected = _this.lodash.concat(_this.rejected, data.rows);
            _this.current_pagetwo = data.current_page + 1;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    /*getCategoryOptions(userList) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "setting/leave/option/" + userList,
        })
        .then(({ data }) => {
          _this.categoryList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },*/

    onRejectedDrop(collection, dropResult) {
      const { addedIndex, payload } = dropResult;
      if (this.lodash.isNull(addedIndex) == false) {
        this.update_loading = true;
        this.$store
          .dispatch(PATCH, { url: `leave/${payload.id}/rejected` })
          .then((output) => {
            console.log({ output });
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.update_loading = false;
          });
      }
      this[collection] = this.applyDrag(this[collection], dropResult);
    },
    onApprovedDrop(collection, dropResult) {
      const { addedIndex, payload } = dropResult;
      if (this.lodash.isNull(addedIndex) == false) {
        this.update_loading = true;
        this.$store
          .dispatch(PATCH, { url: `leave/${payload.id}/approved` })
          .then((output) => {
            console.log({ output });
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.update_loading = false;
          });
      }
      this[collection] = this.applyDrag(this[collection], dropResult);
    },
    onPendingDrop(collection, dropResult) {
      const { addedIndex, payload } = dropResult;
      if (this.lodash.isNull(addedIndex) == false) {
        this.update_loading = true;
        this.$store
          .dispatch(PATCH, { url: `leave/${payload.id}/pending` })
          .then((output) => {
            console.log({ output });
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.update_loading = false;
          });
      }
      this[collection] = this.applyDrag(this[collection], dropResult);
    },

    getChildPayload1(index) {
      return this.pending[index];
    },
    getChildPayload2(index) {
      return this.approved[index];
    },
    getChildPayload3(index) {
      return this.rejected[index];
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },

    updateLeave(id) {
      this.$emit("update:leave", id);
    },
  },
  mounted() {
    this.getLeave("pending");
    this.getLeave("rejected");
    this.getLeave("approved");
    this.getCategoryOptions();
  },
  components: {
    Container,
    Draggable,
  },
};
</script>
