<template
  :routePreventDialog="routePreventDialog"
  :customClass="'leave-update'"
  v-if="getPermission('leave:update') || getPermission('leave:create')"
>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 99"
    >
      <div class="drawer-wrapper">
        <v-form
          ref="leaveForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="CreateOrupdateLeave"
        >
          <v-flex
            class="py-1 px-5 d-flex align-items-center white--text orange darken-2"
          >
            <div class="form-title my-auto">Create Leave</div>
            <v-spacer></v-spacer>
            <v-btn
              tile
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="custom-bold-button"
              color="cyan white--text"
              v-on:click="CreateOrupdateLeave"
              >Save</v-btn
            >
            <v-btn tile color="" class="ml-2" v-on:click="$emit('close')"
              >Cancel</v-btn
            >
          </v-flex>
          <div class="drawer-content pt-5 px-5">
            <div class="d-form-grp">
              <label class="my-auto fw-500" required>Select Staff</label>
              <v-autocomplete
                hide-details
                dense
                solo
                flat
                placeholder="Select Staff"
                :items="userList"
                :disabled="pageLoading"
                :loading="pageLoading"
                item-value="id"
                item-text="full_name"
                v-on:change="getCategoryOptions"
                v-model.trim="leaveCreate.staff"
              ></v-autocomplete>
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500" required>Category</label>
              <v-autocomplete
                hide-details
                dense
                solo
                flat
                placeholder="Category"
                item-value="category"
                v-model="leaveCreate.category"
                :items="categoryList"
                item-text="category"
                v-on:change="categoryDays"
              ></v-autocomplete>
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500">Leave Reason</label>
              <v-text-field
                hide-details
                dense
                solo
                flat
                placeholder="Reason"
                v-model.trim="leaveCreate.reason"
              ></v-text-field>
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500">Start Date</label>
              <Datepicker
                solo
                flat
                placeholder="Start Date"
                v-model="start_date"
                :min-date="minDate"
              ></Datepicker>
              <!-- <Datepicker
                solo
                flat
                v-model.trim="leaveCreate.start_date"
                :min-date="minDate"
              ></Datepicker>-->
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500">End Date</label>
              <Datepicker
                solo
                flat
                placeholder="End Date"
                v-model.trim="end_date"
                :min-date="start_date"
                :max-date="maxDate"
              ></Datepicker>
              <!--<Datepicker
                solo
                flat
                v-model.trim="leaveCreate.end_date"
                :min-date="leaveCreate.start_date"
              ></Datepicker>-->
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500">Half Day</label>
              <v-switch
                v-model="leaveCreate.duration"
                dense
                hide-details
                class="ma-0"
              ></v-switch>
            </div>
            <div class="d-form-grp mt-4" v-if="leaveCreate.duration == 1">
              <label class="my-auto fw-500"></label>
              <v-radio-group
                v-model="leaveCreate.duration_type"
                row
                dense
                hide-details
                class="mt-0"
              >
                <v-radio label="First half" value="1" class="ma-0"></v-radio>
                <v-radio label="Second half" value="2" class="ma-0"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-form>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  GET,
  PUT,
} from "@/core/services/store/request.module";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "CreateLeave",
  components: {
    Datepicker,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    leaveUpdateData: {
      type: Object,
      default: new Object({}),
    },
  },
  data() {
    return {
      minDate: new Date().toISOString().substr(0, 10),
      leave: null,
      pageLoading: false,
      categoryList: new Array(),
      start_date: null,
      end_date: null,
      maxDate: null,
      userList: new Array(),
      leaveList: [
        {
          category: null,
          maxdays: null,
        },
      ],
      leaveCreate: new Object({
        id: null,
        staff: null,
        category: null,
        duration: 0,
        duration_type: 1,
        reason: null,
      }),
    };
  },
  watch: {
    leaveUpdateData(param) {
      this.leaveCreate = {
        id: param.id,
        staff: (param.staff && param.staff.id) || null,
        category: (param.category && param.category.id) || null,
        duration: param.duration || 0,
        duration_type: param.duration_type || 1,
        start_date: param.start_date || null,
        end_date: param.end_date || null,
        reason: param.reason || null,
      };
    },
    start_date(param) {
      this.categoryDays();
      this.end_date = param;
    },
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/options",
        })
        .then(({ data }) => {
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    categoryDays() {
      const _this = this;
      let categoryData = _this.lodash.filter(
        _this.categoryList,
        function (row) {
          return row.category === _this.leaveCreate.category;
        }
      );
      _this.maxDate = moment(this.start_date, "YYYY-MM-DD")
        .add(categoryData[0].maxdays - 1, "days")
        .format("YYYY-MM-DD");
    },

    getCategoryOptions(userList) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "setting/leave/option/" + userList,
        })
        .then(({ data }) => {
          _this.categoryList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    /* CreateLeave() {
      const _this = this;
      if (!_this.$refs.leaveForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        staff: _this.leaveCreate.staff || null,
        duration: _this.leaveCreate.duration || 0,
        duration_type: _this.leaveCreate.duration_type || 1,
        start_date: _this.leaveCreate.start_date || null,
        end_date: _this.leaveCreate.end_date || null,
        reason: _this.leaveCreate.reason || null,
      });

      _this.$store
        .dispatch(POST, {
          url: "leave" ,
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("leave", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },*/

    CreateOrupdateLeave() {
      const _this = this;
      if (!_this.$refs.leaveForm.validate()) {
        return false;
      }
      //_this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});
      let leaveId = _this.leaveCreate.id || 0;
      let formData = new Object({
        id: leaveId,
        staff: _this.leaveCreate.staff || null,
        category: _this.leaveCreate.category || null,
        duration: _this.leaveCreate.duration || 0,
        duration_type: _this.leaveCreate.duration_type || 1,
        start_date: _this.start_date || null,
        end_date: _this.end_date || null,
        reason: _this.leaveCreate.reason || null,
      });
      if (leaveId > 0) {
        _this.updateLeavecase(formData, leaveId);
      } else {
        _this.createLeavecase(formData);
      }
    },

    updateLeavecase(formData, leaveId) {
      const _this = this;
      _this.$store
        .dispatch(PUT, {
          url: "leave/" + leaveId,
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("leave", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    createLeavecase(formData) {
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "leave",
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("leave", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLeave() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/" + _this.leave,
        })
        .then(({ data }) => {
          _this.leaveCreate = new Object({
            staff: data.staff ? data.staff.staff : null,
            duration: data.duration,
            duration_type: data.duration_type,
            reason: data.reason,
            status: data.status,
          });
          _this.start_date = data.start_date;
          _this.end_date = data.end_date;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leave", route: "leave" },
      { title: "create" },
    ]);
    _this.leave = _this.lodash.toSafeInteger(_this.$route.params.id);

    /* if (_this.leave <= 0) {
      _this.goBack();
    } */
    //_this.getLeave();
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    _this.routePreventDialog = true;
    PreventDialogEventBus.$on("stay-on-page", function () {
      _this.routePreventDialog = false;
      next(false);
    });
    PreventDialogEventBus.$on("leave-page", function () {
      _this.routePreventDialog = false;
      next();
    });
  },
};
</script>
